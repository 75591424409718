body {
  margin: 0px;
}


.content {
  margin: 0;
  padding-top: 30px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  justify-items: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
  font-family: 'Franklin Gothic', 'Arial', sans-serif;
  font-size: 10pt;
  display: flex;
  flex-direction: column;
}

.footer p{
  margin-top: -10px;
  padding-top: 0px;
}

.item-pair {
  padding-top: 20px;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.item-container {
  width: 50%;
  max-width: 400px; /* Adjust this value as needed */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-container img {
  width: auto;
  height: 100%;
  max-height: 50vh;
}

.navbar-left {
  background-color: maroon;
  color: white;
  width: 50%;
  height: 10vh;
  max-height: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.navbar-right {
  background-color: midnightblue;
  color: white;
  width: 50%;
  height: 10vh;
  max-height: 8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.navbar-link {
  color: white;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: 'Franklin Gothic', 'Arial', sans-serif;
  font-size: 30pt;
}

.navbar-link:visited {
  color: white;
}

.navbar-list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.navbar-item {
  margin: 0 10px;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.total-votes-text {
  padding-bottom: 1.0rem;
  font-size: 12pt;
  font-family: 'Franklin Gothic', 'Arial', sans-serif;
  color: #222222;
}

.home-page-text {
  padding-top: 1rem;
  font-size: 50pt;
  font-family: 'Franklin Gothic', 'Arial', sans-serif;
  font-weight: bold;
  color: #222222;
}
